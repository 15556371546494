/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import { ReactComponent as SparklizerLogo } from "./media/sparklizer_logo.svg";

function Sparklize() {

    const [ccSpark, setCCSpark] = useState(null);
    const [cc, setCC] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [msg, setMsg] = useState("...and wait for your SPARK CC here.");

    useEffect(() => {
        const sendCC = async () => {
            let jsonres = await (await fetch(`http${(process.env.REACT_APP_HTTP_APPEND && "s")}://${process.env.REACT_APP_API_URL}/sparklize`,
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body:JSON.stringify({
                        colorcode: cc
                    })

                })).json();
            if (jsonres.success === true) {
                setCCSpark(jsonres.colorcode);
                setMsg("Here you go.");
            }
            else {
                setMsg(jsonres.message);
            }
            setFetching(false);
        }

        if (fetching && ccSpark === null) {
            sendCC();
        }

    }, [fetching, msg, ccSpark, cc])

    const resetFetch = () => {
        setMsg("...");
        setCCSpark(null);
        setFetching(true);
    }

    return (
        <>
        <SparklizerLogo className="small-12 medium-6" />
        <div className="readable-content grid-container grid-margin-x grid-x align-center-middle small-11 medium-10">
            <div className="cell small-10 medium-5 input-area">
                <div className="grid-y grid-margin-x" style={{ width: "90%", height: "20em" }}>
                    <div className="cell align-center-middle">
                        <p>Put your color code in here...</p>
                    </div>
                    <div className="cell small-9 align-center-middle" style={{ display: "flex" }}>
                        <textarea onChange={e => {setCC(e.target.value)}}>

                        </textarea>
                    </div>
                </div>

            </div>
            <div className="cell small-12 medium-2 input-area">
                <div className="grid-y align-center-middle" style={{ textAlign: "center" }}>
                    <div className="cell">
                        <button onClick={() => resetFetch()}>
                            SPARKLIZE
                        </button>
                    </div>
                </div>

            </div>
            <div className="cell small-10 medium-5 input-area">
                <div className="grid-y grid-margin-x" style={{ width: "90%", height: "20em" }}>
                    <div className="cell align-center-middle">
                        <p>{msg}</p>
                    </div>
                    <div className="cell small-9 align-center-middle" style={{ display: "flex" }}>
                        <textarea readOnly value={(ccSpark == null ? "" : ccSpark.toUpperCase())}>
                        </textarea>
                    </div>
                </div>

            </div>
        </div>
        </>
    )
}

export default Sparklize;