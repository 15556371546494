/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from "react";
import "./css/main.scss";
import "./css/animations.scss";
import "./App.scss";
import "./css/cmts.scss";
import { useHistory } from "react-router-dom";

import { ReactComponent as CMTSLogo } from "./media/cmts_logo.svg";
import { ReactComponent as CMTSNETLogo } from "./media/cmts_net_logo.svg";
import { ReactComponent as CMTS2Anniversary } from "./media/cmts_2ann.svg";
import { ReactComponent as SearchIcon } from "./icons/lookup_id.svg";

function MainSearch() {
  return (
    <>
    <div style={{ display: "flex", width: "80%", justifyContent: "center"}}>
        <CMTSLogo className="small-2" style={{ height: "30%" }} />
        <CMTS2Anniversary className="small-2" style={{ width: "30%", paddingLeft: "2rem" }} />
        </div>
        

      <CMTSNETLogo className="small-12 medium-6" />
      <NewsMicroBanner />
      <SearchBar initial="true" />
    </>
  );
}

function NewsMicroBanner() {
  const [ccStats, setCCStats] = useState(null);

  useEffect(
    (upd) => {
      async function getStat() {
        try {
          let jsonres = await fetch(
            `http${process.env.REACT_APP_HTTP_APPEND && "s"}://${
              process.env.REACT_APP_API_URL
            }/info/statistics`,
            {
              method: "GET",
            }
          );
          if (jsonres.status === 200) {
            setCCStats(await jsonres.json());
          }
        } catch (ex) {
          setCCStats({
            error: true,
          });
        }
      }

      if (ccStats === null) {
        getStat();
      }
    },
    [ccStats]
  );

  const ccStatsText = () => {
    if (ccStats === null) {
      return "Getting info, please wait...";
    }
    if (!ccStats.error) {
      return `Proudly hosting ${ccStats.totalCount} color codes and 0 CMTP Mods.`;
    } else {
      return "Something happened while getting CC count, sorry!";
    }
  };

  return (
    <>
      <div className="cell readable-content" style={{ textAlign: "center" }}>
        <div className="" style={{ padding: "1em" }}>
          <div>{ccStatsText()}</div>
          <div>
            Consider joining{" "}
            <a href="https://discord.gg/DEyeFCPRAu">
              the Project Comet Discord
            </a>
          </div>
          <br></br>
          <div>
            <strong>
              Please report any issues as soon as you see them, no matter how
              small.
            </strong>
          </div>
        </div>
      </div>
    </>
  );
}

function SearchBar(props) {
  const [query, setQuery] = useState("");
  const [initQuery, setInitQuery] = useState(true);
  const [validQuery, setValidQuery] = useState(true);
  const nav = useHistory();

  const keyPress = (e) => {
    e.persist();
    if (e.charCode === 13) {
      nav.push(`/search?q=${query}`);
    }
  };

  useEffect(() => {
    if (initQuery) {
      if (
        (props.existingQuery ||
          props.existingQuery === false ||
          props.existingQuery === 0) &&
        props.existingQuery !== query
      ) {
        setQuery(props.existingQuery);
        setInitQuery(false);
        setValidQuery(true);
      } else if (!props.initial) {
        setValidQuery(false);
      }
    }
  }, [initQuery, query, props.existingQuery, props.initial]);

  if (validQuery) {
    return (
      <>
        <div className="searchBar small-12 large-6">
          <input
            type="text"
            defaultValue={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={keyPress}
          ></input>
          <button
            className={"searchButton"}
            onClick={() => nav.push(`/search?q=${query}`)}
          >
            <SearchIcon className="image"></SearchIcon>
          </button>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="searchBar small-12 large-6 error-shake">
          <input
            type="text"
            defaultValue={query}
            onChange={(e) => setQuery(e.target.value)}
          ></input>
          <button
            className={"searchButton"}
            onClick={() => nav.push(`/search?q=${query}`)}
          >
            <SearchIcon className="image"></SearchIcon>
          </button>
        </div>
      </>
    );
  }
}

export default MainSearch;
export { SearchBar };
