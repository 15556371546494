import React, { Fragment, useState, useEffect } from 'react';
import { SearchBar } from "./cmtsSearch.js";
import { useLocation } from 'react-router';
import { Link } from "react-router-dom"
import StatusIcon from "./components/cmtsStatusIcon.js";
const fetch = require("node-fetch");
function CMTSResultsPage(props) {
    const [fetching, setFetching] = useState(true);
    const [results, setResults] = useState([]);
    const [empty, setEmpty] = useState(false);
    const [canFetch, setCanFetch] = useState(true);
    const [error, setError] = useState(0);
    const [errorDetail, setErrorDetail] = useState("");
    const [maintenanceEta, setMaintenanceEta] = useState(0);
    let qparams = new URLSearchParams(useLocation().search);
    let query = qparams.get("q");
    const [lastQuery, setLastQuery] = useState(query);

    useEffect((upd) => {
        async function getDataWrap() {
            if (!query || query === false || query === 0) {
                // Error -1: Invalid search
                setError(-1);
                setFetching(false);
                setCanFetch(false);
                return;
            }
            try {
                let jsonres = await (await fetch(`http${(process.env.REACT_APP_HTTP_APPEND && "s")}://${process.env.REACT_APP_API_URL}/users/search?search=${query}`,
                    {
                        method: "GET"
                    })).json();
                switch (jsonres.code) {
                    case 201:
                        if (jsonres.results.length === 0) {
                            setEmpty(true);
                            setResults([]);
                            setError(0);
                        }
                        break;
                    case 200:
                        setEmpty(false);
                        setResults(jsonres.results);
                        setError(0);
                        break;
                    case 301:
                        // Error 2: Not ready
                        setError(2);
                        setErrorDetail(jsonres.message);
                        break;
                    case 398:
                        // Error 3: Maintenance
                        setError(3);
                        setErrorDetail(jsonres.message);
                        setMaintenanceEta(jsonres.eta);
                        break;
                    case 399:
                    default:
                        // Error 4: Emergency
                        setError(4);
                        setErrorDetail(jsonres.message);
                        setMaintenanceEta(jsonres.eta);
                        break;
                }
            } catch (ex) {
                // Error 1: Disconnected from server
                setError(1);
            }
            setFetching(false);
            setCanFetch(false);
            setLastQuery(query);
        }
        if (canFetch) {
            setError(0);
            getDataWrap();
        }
        if (lastQuery !== query) {
            setCanFetch(true);
            setFetching(true);
        }
    }, [canFetch, query, lastQuery]);
 
    const generateUserBadgeList = (user) => {
        let concat = [];
        if (user.meta.verified) {
            concat.push(<Fragment key="v"><div className="icon verified" title="Verified User"></div></Fragment>);
        }
        if (user.meta.supportLevel === 3) {
            concat.push(<Fragment key="p"><div className="icon patron" title="Patron"></div></Fragment>);
        }
        if (user.meta.supportLevel > 3 || user.meta.modLevel > 0) {
            concat.push(<Fragment key="m"><div className="icon mod" title="CMTS Moderator"></div></Fragment>);
        }

        return concat;
    }

    const generateSearchResults = (jsonInput) => {
        return jsonInput.map(x => {
            return (
                <Fragment key={x.id}>
                    <Link to={`/${x.handle || x.id}/cc`}>
                        <div className="grid-x grid-padding-x grid-padding-y searchResult">
                            <div className="cell small-2">
                                <img src={x.avatar} alt="User Avatar" />
                            </div>
                            <div className="grid-x" style={{ alignItems: "center" }}>
                                {(x.handle === null ?
                                    <>
                                        <span style={{ fontWeight: "bold", color: "white" }}>{x.displayname}</span>
                                        <span style={{ fontWeight: "100", color: "whitesmoke" }}>#{x.discriminator}</span>
                                    </>
                                    : <span style={{ fontWeight: "bold", color: "white" }}>@{x.handle}</span>)}
                                <div className="badgeTray">
                                    {generateUserBadgeList(x)}
                                </div>
                            </div>
                        </div>
                    </Link>
                </Fragment>
            )
        })
    }


    if (fetching) {
        return (
            <>
                <SearchBar existingQuery={lastQuery} />
                <StatusIcon type="loading" />
            </>
        )
    }
    if (error === -1) {
        return (
            <>
                <SearchBar existingQuery={lastQuery} />
                <div className="small-6">
                    <div className="grid-x bannerResults bannerImage">
                        <div>Nothing to search.</div>
                    </div>
                </div>
            </>
        )
    }
    else if (error > 0) {
        switch (error) {
            case 2:
                return (
                    <>
                        <SearchBar existingQuery={lastQuery} />
                        <StatusIcon type="not-ready" info={{ reason: errorDetail }} />
                    </>)
            case 3:
                return (
                    <>
                        <SearchBar existingQuery={lastQuery} />
                        <StatusIcon type="maintenance" info={{ reason: errorDetail, eta: new Date(maintenanceEta*1000).toDateString() }} />
                    </>)
            case 4:
                return (
                    <>
                        <SearchBar existingQuery={lastQuery} />
                        <StatusIcon type="maintenance-unplanned" info={{ reason: errorDetail, date: new Date(maintenanceEta*1000).toDateString() }} />
                    </>)
            case 1:
            default:
                return (
                    <>
                        <SearchBar existingQuery={lastQuery} />
                        <StatusIcon type="disconnected" />
                    </>)
        }
    }
    if (empty) {
        return (
            <>
                <SearchBar existingQuery={lastQuery} />
                <SearchResultsBanner results={results.length} />
            </>
        )
    }
    else {
        return (
            <>
                <SearchBar existingQuery={lastQuery} />
                <SearchResultsBanner results={results.length} />
                <div className="searchResults small-12 medium-9">
                    <div className="grid-container">
                        {
                            // TODO: Implement pagination.
                            // This will be page-based pagination, API will return
                            // total number of pages (fixed results per page)
                            // and we will read it here according to page
                            generateSearchResults(results)
                        }
                    </div>
                </div>
            </>
        )
    }
}

function SearchResultsBanner(props) {

    return (
        <div className="small-6">
            <div className="grid-x bannerResults bannerImage">
                {props.results === 0 &&
                    <div className="result"> No results. </div>
                }
                {props.results > 0 &&
                    <div className="result"> {props.results} results. </div>
                }
            </div>
        </div>
    )
}

export default CMTSResultsPage;