import {
    Link,
    useRouteMatch
} from "react-router-dom";

function ListRouterLink({ label, to, activeOnlyWhenExact, onClick }) {
    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });

    return (
        <Link to={to} onClick={onClick}>
            <li className={match ? "active" : ""}>
                {label}
            </li>
        </Link>
    )
}

function RouterLink({ label, to, activeOnlyWhenExact, onClick }) {
    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });

    return (
        <Link to={to} onClick={onClick} className={match ? "active" : ""}>
            {label}
        </Link>
    )
}

export { ListRouterLink, RouterLink }