import React from 'react';
function StatusIcon({ type, style, info }) {
    const statusMap = (index) => {
        if (!info) {
            info = {};
        }
        let strings = {
            "unspecified": ["catastrophe", "Something weird has happened! Please report this immediatly at the Project Comet server."],
            "loading": ["loading", null],
            "loading-caption": ["loading", `${info.comment}`],
            "disconnected": ["disconnect", "Could not connect to CMTS's servers.\nCheck your internet connection."],
            "banned": ["banned", `You have been banned from doing this action. Contact an admin.\nReason: ${info.reason || "None."} // ${info.date || "Unknown date"}`],
            "maintenance": ["mant", `CMTS's servers are under planned maintenance.\nReason: ${info.reason || "None."} since ${info.date || "Unknown date"}. Check Project Comet for updates.`],
            "maintenance-date": ["mant", `CMTS's servers are under planned maintenance.\nReason: ${info.reason || "None."} since ${info.date || "Unknown date"}. Expected to return at ${info.eta || "an unknown time."}`],
            "not-ready": ["mant", `This CMTS feature is not ready for the moment being.\nPlease wait a moment.\nReason: ${info.reason || "None specified. Backend is likely warming up."}`],
            "maintenance-unplanned": ["unplanned-mant", `CMTS's servers are under emergency maintenance.\nReason: ${info.reason || "None."} since ${info.date || "an unknown date"}`],
            "authorization": ["login", "You're not authorized to perform this action. You may need to log in."],
            "catastrophe": ["catastrophe", "Catastrophic error. Please contact GlitchyPSI to find a way to fix this problem."],
            "404": ["notfound", "Not found! Are you lost?"],
            "404-cclist": ["notfound", "There are no color codes here."],
            "404-cc": ["notfound", `There's no CC in this slot (Slot ${info.slot}), or it's hidden from public view.`],
            "404-maybe": ["notfound", "Nothing was found here, but that may be a mistake! If you think it is, please contact GlitchyPSI at Project Comet"],
        }
        return (strings[type] || strings.unspecified);
    }

    return (
        <>
            <div className="grid-y align-center-middle">
                <div className={`statusIcon ${statusMap(type)[0]}`} style={style} />
                {(statusMap(type)[1] != null ? <div className="status-text">{statusMap(type)[1].split('\n').map((item, i) => <p key={i}>{item}</p>)}</div> : "")}
            </div>
        </>
    )

}

export default StatusIcon;