import React, { Fragment, useState, useEffect, useRef, useLayoutEffect } from 'react';
import StatusIcon from "./components/cmtsStatusIcon.js";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMediaQuery } from 'react-responsive'
import { faCopy, faEllipsisH, faShare, faTimes, faThumbsUp, faRainbow, faHandMiddleFinger } from '@fortawesome/free-solid-svg-icons';
import { easeOutExpo, hsvToHslCss, roundRect } from './helpers/canvasHelpers.js';
import Modal from 'react-modal';
import { fuzzyTime } from "./helpers/formatHelpers.js";
const _ = require("lodash");
const fetch = require("node-fetch");


Modal.setAppElement(document.querySelector('#page-bounds'));

const friendlyNames = {
    hats: "Hats (& classic Shirts)",
    overalls: "Overall Top (& classic Overalls)",
    gloves: "Gloves",
    shoes: "Shoes",
    skin: "Skin",
    hair: "Hair",
    arms: "Arm Top (& classic Hat/Shirt)",
    pants: "Pants (& classic Overalls)",
    legs: "Leg Top (& classic Overalls)"
}

const friendlyValues = {
    classic: ["hats", "hair", "skin", "overalls", "gloves", "shoes"],
    spark: ["arms", "pants", "legs"]
}

const friendlyBrightnessNames = [
    "Penumbra (< 15% bright)",
    "Dark (15% - 28.33% bright)",
    "Dim (28.33% - 56.67% bright)",
    "Gloomy (56.67% - 85% bright)",
    "Vivid (> 85% bright)"
]

const friendlySaturationNames = [
    "Pure",
    "Washed",
    "Dull",
    "Grayn't"
]

const hsvBrightnesses = [
    [0, 100, 0],
    [0, 100, 15],
    [0, 100, 28.33],
    [0, 100, 56.67],
    [0, 100, 85],
];

function StatisticsPage() {
    const [statData, setStatData] = useState(null);
    // Structured like this:
    // Error code (TODO: Make a general error handler)
    // Error message
    // ETA (Date string)
    const [fetchErr, setFetchErr] = useState([0, "", ""]);
    useEffect(() => {


        async function getGraphData() {
            try {
                let jsonres = await (await fetch(`http${(process.env.REACT_APP_HTTP_APPEND && "s")}://${process.env.REACT_APP_API_URL}/info/statistics?type=1`,
                    {
                        method: "GET"
                    })).json();
                switch (jsonres.code) {
                    case 200:
                        setStatData(jsonres);
                        setFetchErr([0, ""]);
                        break;
                    case 301:
                        // Error 2: Not ready
                        setFetchErr([2, jsonres.message]);
                        break;
                    case 398:
                        // Error 3: Maintenance
                        setFetchErr([3, jsonres.message, jsonres.eta]);
                        break;
                    case 399:
                        
                        // Error 4: Emergency
                        setFetchErr([4, jsonres.message, jsonres.date]);
                        break;
                    default:
                        // Error 4: Emergency
                        setFetchErr([5, jsonres.message, jsonres.date]);
                        break;
                }
            } catch (ex) {
                // Error 1: Disconnected from server
                setFetchErr([1, "", ""]);
            }
        }

        if (!statData && !fetchErr[0]) {
            getGraphData();
        }
    }, [statData, fetchErr])

    if (fetchErr[0] > 0) {
        switch (fetchErr[0]) {
            case 2:
                return (
                    <StatisticsPanel>
                        <StatusIcon type="not-ready" info={{ reason: fetchErr[1] }} />
                    </StatisticsPanel>)
            case 3:
                return (
                    <StatisticsPanel>
                        <StatusIcon
                            type="maintenance"
                            info={{ reason: fetchErr[1], eta: new Date(fetchErr[2]*1000).toDateString() }} />
                    </StatisticsPanel>)
            case 4:
                return (
                    <StatisticsPanel>
                        <StatusIcon
                            type="maintenance-unplanned"
                            info={{ reason: fetchErr[1], date: new Date(fetchErr[2]*1000).toDateString() }} />
                    </StatisticsPanel>)
            case 5:
                return (
                    <StatisticsPanel>
                        <StatusIcon
                            type="catastrophe"
                            info={{ }} />
                    </StatisticsPanel>)
            case 1:
            default:
                return (
                    <StatisticsPanel>
                        <div className="grid-y align-center-middle">
                            <StatusIcon type="disconnected" />
                        </div>
                    </StatisticsPanel>)
        }
    }

    return (
        <StatisticsPanel>
            <RainbowGraph statData={statData} />
            <PercentagesPanel statData={statData}></PercentagesPanel>
        </StatisticsPanel>
    )

}

function GraphLastUpdatedText({ statData }) {
    const [openCCModal, setOpenCCModal] = useState(false);
    const [updateCounter, setUpdateCounter] = useState(true);
    useEffect(() => {
        if (updateCounter)
            setUpdateCounter(false);
    }, [updateCounter])
    useEffect(() => {
        setTimeout(() => { setUpdateCounter(true) }, 2000);
    })
    return (
        <div className="readable-content" style={{ textAlign: "center", padding: "1em", margin: "1em", wordBreak: "break-word" }}>
            <em style={{ color: "white" }}>This chart's data was last updated: {fuzzyTime(statData.chartLastUpdate)}</em>
            <p>The chart data is updated server-side every 6 minutes.<br />To get the latest data, refresh this page.</p>
            <p>You may use these stats for any purpose, as they are licensed under <strong>CC BY-SA 4.0</strong> <button onClick={() => { setOpenCCModal(true) }}>Learn more</button></p>
            <CCBYModal modalOpen={openCCModal} closeModal={() => { setOpenCCModal(false) }} />
        </div>
    )
}

function CCBYModal({ modalOpen, closeModal }) {
    return (
        <Modal
            className="cell share-modal"
            overlayClassName="grid-x share-modal-overlay align-center align-middle"
            parentSelector={() => document.querySelector('#page-bounds')}
            isOpen={modalOpen}
            onRequestClose={closeModal}
            closeTimeoutMS={520}
            contentLabel="Share modal"
            ariaHideApp={false}
            style={{ width: "100%" }}
        >
            <div className="grid-x modal-content align-center" style={{ color: "white" }}>
                <div className="cell grid-x grid-margin-x grid-padding-y medium-9 small-11">
                    <div className="cell grid-y grid-margin-y">
                        <div className="cell grid-x small-1 align-center-middle">
                            <div className="cell small-12 grid-x align-right">
                                <button className="empty-button" onClick={() => closeModal()} ><FontAwesomeIcon role="button" style={{ color: "#FFF", fontSize: "1.6em", cursor: "pointer" }} icon={faTimes} /></button>
                            </div>
                        </div>
                        <div className="grid-x align-right">
                            <div className="cell auto title"><h1>Statistics license: CC BY-SA 4.0</h1></div>
                        </div>
                        <div className="grid-x align-center-middle">
                            <img alt="CC BY-SA 4.0" style={{ width: "10em" }} src="https://upload.wikimedia.org/wikipedia/commons/d/d0/CC-BY-SA_icon.svg"></img>
                        </div>
                        <div className="readable-content" style={{ textAlign: "center" }}>
                            <p>These statistics are licensed under CreativeCommons Attribution-ShareAlike 4.0.
                                <br /> This means you can use them anywhere for your video purposes, essays, etc, as long as you credit me (GlitchyPSI @ Project Comet) and make your contributions equally licensed. <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">Read More</a><br /><br />
                                To preserve data integrity, please do not modify the statistics data in any way.
                            </p>
                        </div>
                        <div className="grid-x align-center-middle">
                            <button onClick={closeModal}>OKAY'OH</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>)
}

function StatisticsPanel({ children }) {
    return (
        <>
            <div className="panel grid-container grid-y full small-12 medium-11">
                <div className="title header">
                    <h1>Statistics</h1>
                </div>
                <div className="bottom large-auto large-cell-block-container" style={{ height: "100%" }}>
                    <div className="grid-y align-center">
                        <div className="grid-x grid-margin-x align-center">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function MaxCCsPanel() {
    return (
        <>
            <div className="small-12" style={{ textAlign: "center" }}>
                <div className="darker grid-y">
                    <p>Within this classification, the total number of possible color codes is...</p>
                    <span><strong style={{ fontSize: "2em" }}>244.140625 Trillion</strong></span>
                    <p>Huge, isn't it?</p>
                </div>
            </div>
        </>
    )
}

function PercentagesPanel({ statData }) {
    useEffect(() => { }, [statData])
    if (statData) {
        return (
            <>
                <div className="small-12" style={{ textAlign: "center" }}>
                    <div className="darker grid-y">
                        <p>There are in total...</p>
                        <span><strong style={{ fontSize: "4em" }}>{statData.totalCount}</strong> customizations</span>
                        <p>...of which...</p>
                        <span><strong style={{ fontSize: "2em" }}>{statData.separateCounts.classic}</strong> <span style={{ fontSize: "0.9em" }}>({(statData.separateCounts.classic * 100 / statData.totalCount).toFixed(2)}%)</span> are Classic CCs</span>
                        <span><strong style={{ fontSize: "2em" }}>{statData.separateCounts.spark}</strong> <span style={{ fontSize: "0.9em" }}>({(statData.separateCounts.spark * 100 / statData.totalCount).toFixed(2)}%)</span> are SPARK CCs</span>
                        <span><strong style={{ fontSize: "2em" }}>0</strong> <span style={{ fontSize: "0.9em" }}>(0%)</span> are CMTP mods</span>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (
            <div className="small-12" style={{ textAlign: "center" }}>
                <div className="darker grid-y">
                    <StatusIcon type="loading-caption" info={{ comment: "Loading data..." }} />
                </div>
            </div>
        )
    }
}

function RainbowGraph({ divisions = 8, statData }) {
    const canvasRef = useRef(null);
    // Can we use the rainbow graph?
    const [initialized, setInitialized] = useState(false);
    // Maximum value of current dataslice
    const [maxValue, setMaxValue] = useState(0);
    // Total frequency (all CCs)
    const [totalFrequency, setTotalFrequency] = useState(0);
    // Structured like this:
    // Error code (TODO: Make a general error handler)
    // Error message
    // ETA (Date string)
    const [fetchErr, setFetchErr] = useState([0, "", ""]);
    // Structured like this:
    // [Selected Part, Brightness(0-4)]
    const [selectedPart, setSelectedPart] = useState(["hats", 4])
    const [dataUpdateNecessary, setDataUpdateNecessary] = useState(false);
    const [graphZoom, setGraphZoom] = useState(1);
    const [usePercents, setUsePercents] = useState(true);
    // Data slice: which part to see
    // Reference:
    // 0 - Both Color and Grays at once
    // 1 - Just colors
    // 2 - Just grays
    const [dataSliceMode, setDataSliceMode] = useState(0);
    // Classes are structured like this:
    // [classId, hsv_start, hsv_end, [color1, ..., color4]]
    const [classes, setClasses] = useState([[], []]);
    // Index of GraphDivSize.
    const [graphDivSize, setGraphDivSize] = useState(1);
    const [ready, setReady] = useState(false);
    const graphDivSizes = [5, 7, 12];
    const isSmall = useMediaQuery({ query: "(max-width: 39.9375em)" });

    function scrollSmoothToId(query) {
        let element = document.querySelector(query);
        if (!element) return;
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }

    useEffect(() => {

        /**
            * Part 1 of graph renderer
            * @param {CanvasRenderingContext2D} ctx
            */
        const drawAxes = (ctx, availableClasses) => {
            let canx = ctx.canvas.width;
            let cany = ctx.canvas.height;
            ctx.fillStyle = 'transparent';
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            // Time for color "swatches"
            let startx = 0.08380;
            let gradY = cany * 0.78;
            let width = 0.8773 / availableClasses.length;
            let endx = startx + width;
            ctx.strokeStyle = "rgba(0,0,0,0.3)";
            ctx.lineWidth = 4;

            // For each class
            availableClasses.forEach((cls) => {
                let grad = ctx.createLinearGradient(canx * startx, gradY, canx * endx, gradY);
                grad.addColorStop(0, cls[1]);
                grad.addColorStop(1, cls[2]);
                ctx.fillStyle = grad;
                ctx.shadowColor = "rgba(0,0,0,0.4)";
                ctx.shadowBlur = "5";
                ctx.shadowOffsetY = 30 / 8;
                roundRect(ctx,
                    canx * startx + (ctx.lineWidth * 2),
                    gradY + (ctx.lineWidth * 1.125),
                    (width * canx) - (ctx.lineWidth * 3.5),
                    30, {
                    tl: 5,
                    tr: 5,
                    br: 5,
                    bl: 5
                }, true, true);
                //ctx.fillRect(canx * startx, gradY, width * canx, 30);
                startx += width;
                endx += width;
            });
            ctx.shadowColor = "rgba(0,0,0,0)";
            ctx.shadowBlur = "0";
            ctx.shadowOffsetY = 0;
            // Here come the lines
            // First line
            let lineGrad = ctx.createLinearGradient(0.08445 * canx - (ctx.lineWidth / 4),
                gradY, 0.08445 * canx - (ctx.lineWidth / 4),
                gradY + 12);
            lineGrad.addColorStop(0, "white");
            lineGrad.addColorStop(1, "rgba(255,255,255,0)");
            ctx.lineWidth = 4;
            ctx.strokeStyle = lineGrad;
            ctx.beginPath();
            ctx.moveTo(0.08445 * canx - (ctx.lineWidth / 4), gradY);
            ctx.lineTo(0.08445 * canx - (ctx.lineWidth / 4), gradY + 12);
            ctx.stroke();
            ctx.closePath();
            // The rest.
            for (let i = 0; i < availableClasses.length; i++) {
                ctx.beginPath();
                ctx.moveTo(0.0838 * canx + (width * canx * (i + 1)) + (ctx.lineWidth / 4), gradY);
                ctx.lineTo(0.0838 * canx + (width * canx * (i + 1)) + (ctx.lineWidth / 4), gradY + 12);
                ctx.stroke();
                ctx.closePath();
            }

            if (!statData) {
                ctx.font = "200 8em Roboto Mono";
                ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
                ctx.textBaseline = "middle";
                ctx.textAlign = "center";
                ctx.fillText(("Retrieving data"), canx * 0.5, cany * .5, canx * 0.8);
                return
            }

            if (!ready) {
                ctx.font = "200 8em Roboto Mono";
                ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
                ctx.textBaseline = "middle";
                ctx.textAlign = "center";
                ctx.fillText(("Crunching numbers"), canx * 0.5, cany * .5, canx * 0.8);
                return
            }

            // Now time to draw graph axes and bars
            if (statData && !isNaN(maxValue)) {
                drawData(ctx, width, availableClasses);
            }
        }

        /**
         * Part 2 of graph renderer
         * @param {CanvasRenderingContext2D} ctx
            */
        const drawData = (ctx, classWidth, availableClasses) => {

            let graysOnly = (availableClasses.length < 12);
            let isAllColors = availableClasses.length > 13;
            let canx = ctx.canvas.width;
            let cany = ctx.canvas.height;
            ctx.font = "400 1.25em Roboto Mono";

            // Draw Y axis guide
            ctx.save();
            ctx.font = "400 3em Roboto Mono";
            ctx.rotate(-Math.PI / 2);
            ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
            ctx.textBaseline = "middle";
            ctx.textAlign = "center";
            ctx.fillText((usePercents ? "% OF ALL COLOR CODES" : "# OF COLOR CODES"), cany * -0.45, canx * 0.025, cany * 0.8);
            ctx.restore();

            // Draw divisions
            ctx.textBaseline = "middle";
            ctx.textAlign = "right";
            ctx.strokeStyle = "white";
            let vertHeight = 0.663 / divisions;
            ctx.fillStyle = "white";

            // Each division
            for (let i = 0; i <= divisions; i++) {
                ctx.strokeStyle = "white";
                ctx.beginPath();
                ctx.moveTo(canx * 0.08380, cany * 0.78 - (vertHeight * cany * i));
                ctx.lineTo(canx * 0.075, cany * 0.78 - (vertHeight * cany * i));
                ctx.stroke();
                ctx.beginPath();
                ctx.strokeStyle = "rgba(255, 255, 255, 0.2)";
                ctx.moveTo(canx * 0.08380, cany * 0.78 - (vertHeight * cany * i));
                ctx.lineTo(canx * 0.963068, cany * 0.78 - (vertHeight * cany * i));
                ctx.stroke();
                if (usePercents) {
                    ctx.fillText(`${(Math.round(((maxValue / graphZoom) / totalFrequency) * 100) / divisions * i)}%`, canx * 0.074, cany * 0.78 - (vertHeight * cany * i), canx * classWidth);
                }
                else {
                    ctx.fillText(`${(Math.round(((maxValue / divisions) * i / graphZoom) * 100)) / 100}`, canx * 0.074, cany * 0.78 - (vertHeight * cany * i), canx * classWidth);
                }

            }



            // About to draw bars.
            ctx.save();
            if (!isAllColors && !graysOnly) {
                // But before we do that, we must talk about Parallel Universes
                // And saturation counts. Those are necessary.
                // They'll be drawn at the end, but we NEED the legend text before that
                let legendExtraHeight = 0;
                ctx.font = "400 0.9em Roboto Mono";
                ctx.textAlign = "right";
                ctx.textBaseline = "top";
                for (let p = 0; p < friendlySaturationNames.length; p++) {
                    let measure = ctx.measureText(`${friendlySaturationNames[p]}}`)
                    if (!(p % 2)) {
                        ctx.fillStyle = "rgba(0,0,0,0.4)";
                        ctx.fillRect(canx * 0.08380,
                            cany * 0.835 + legendExtraHeight,
                            canx * 0.8773,
                            measure.actualBoundingBoxDescent)
                        ctx.fillStyle = "white";
                    }

                    ctx.fillText(`${friendlySaturationNames[p]}`,
                        (canx * 0.086) - (classWidth / 4 * canx),
                        cany * 0.835 + legendExtraHeight,
                        (classWidth * canx) - (6));
                    legendExtraHeight += measure.actualBoundingBoxDescent + 6;
                }
            }

            for (let xAxisIndex = 0;
                xAxisIndex < availableClasses.length;
                xAxisIndex++) {

                // What a doozy just to have all values in the same array.
                // Before 12: all Color
                // After 12: treat differently, all Gray
                let brightnessSectionTotal = 0;
                let totalBlockHeight = 0;
                if (xAxisIndex < 12 && !graysOnly) {
                    brightnessSectionTotal = statData.chartInfo[selectedPart[0]].colorFrequencies[xAxisIndex * 30][selectedPart[1]].reduce((a, b) => a + b, 0);
                    totalBlockHeight =
                        (0.66585) * (maxValue ? brightnessSectionTotal / (maxValue) : 0);
                }
                else if (graysOnly) {
                    totalBlockHeight = (0.66585) * statData.chartInfo[selectedPart[0]].grayFrequencies[xAxisIndex] / (maxValue);
                }
                else {
                    totalBlockHeight = (0.66585) * statData.chartInfo[selectedPart[0]].orderedTotalFrequencies[xAxisIndex] / (maxValue);
                }

                totalBlockHeight *= graphZoom;

                ctx.lineWidth = 5;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.4)";
                ctx.lineJoin = "round";
                ctx.shadowColor = "rgba(0,0,0,0.4)";
                ctx.shadowBlur = 5;
                ctx.shadowOffsetY = 30 / 8;
                ctx.strokeRect(
                    (canx * 0.08380) + (ctx.lineWidth * 2) + (classWidth * canx * xAxisIndex),
                    (cany * 0.78) - (ctx.lineWidth),
                    (classWidth * canx) - (ctx.lineWidth * 3.5),
                    -totalBlockHeight * cany);
                ctx.shadowColor = "transparent";

                // If color: stacked bars with all 3 saturations
                if (xAxisIndex < 12 && !graysOnly) {
                    let lastY = (cany * 0.78);
                    for (let i = 0;
                        // Future proofing just in case, length of the first
                        // saturation divisions
                        i < 4;
                        i++) {
                        ctx.fillStyle = availableClasses[xAxisIndex][3][i];
                        // Holy Mother of Array Accesses.
                        // Get the height of this Saturation block

                        let mult =
                            (statData.chartInfo[selectedPart[0]].colorFrequencies[xAxisIndex * 30][selectedPart[1]][i]
                                / brightnessSectionTotal)
                        mult = mult * totalBlockHeight;
                        // Draw this Saturation Block
                        ctx.fillRect(
                            (canx * 0.08380) + (ctx.lineWidth * 2) + (classWidth * canx * xAxisIndex),
                            lastY,
                            (classWidth * canx) - (ctx.lineWidth * 3.5),
                            (-mult * cany));

                        if (i < 3) {
                            // Draw division lines for clarity if not last bit
                            ctx.beginPath();
                            ctx.moveTo((canx * 0.08380) + (ctx.lineWidth * 2) + (classWidth * canx * xAxisIndex), lastY - mult * cany + ctx.lineWidth / 2);
                            ctx.lineTo((canx * 0.08380) + (ctx.lineWidth * 2) + (classWidth * canx * xAxisIndex) + (classWidth * canx) - (ctx.lineWidth * 3.5),
                                lastY - mult * cany + (ctx.lineWidth / 2));
                            ctx.stroke();
                            ctx.closePath();
                        }

                        // To set the start point for the next Saturation block
                        lastY -= (mult * cany) + ctx.lineWidth / 4;

                        // Draw saturation frequencies
                        ctx.fillStyle = "white";
                        if (!isAllColors) {
                            let extraHeight = 0;
                            ctx.textAlign = "center";
                            ctx.textBaseline = "top"
                            let partInfo = statData.chartInfo[selectedPart[0]].colorFrequencies[xAxisIndex * 30][selectedPart[1]];
                            for (let p = 3; p >= 0; p--) {

                                let measure = ctx.measureText(`${partInfo[p]}}`)
                                ctx.fillText(`${(usePercents ? `${(partInfo[p] / brightnessSectionTotal).toFixed(2)}%` : partInfo[p])}`,
                                    (canx * 0.08380) + (classWidth * canx * xAxisIndex) + (classWidth / 2 * canx),
                                    cany * 0.835 + extraHeight,
                                    (classWidth * canx) - (ctx.lineWidth * 2));
                                extraHeight += measure.actualBoundingBoxDescent + 6.5;
                            }

                        }
                    }
                    continue;
                }

                // Black and white
                ctx.fillStyle = availableClasses[xAxisIndex][3][0];
                ctx.fillRect(
                    (canx * 0.08380) + (ctx.lineWidth * 2) + (classWidth * canx * xAxisIndex),
                    (cany * 0.78) - (ctx.lineWidth),
                    (classWidth * canx) - (ctx.lineWidth * 3.5),
                    -totalBlockHeight * cany);
                ctx.fillStyle = "white";

                if (!isAllColors) {
                    // Draw gray frequencies
                    let freq = statData.chartInfo[selectedPart[0]].grayFrequencies[xAxisIndex];
                    ctx.textAlign = "center";
                    ctx.fillText(`${usePercents ? `${(freq / maxValue).toFixed(2)}%` : freq}`,
                        (canx * 0.08380) + (classWidth * canx * xAxisIndex) + (classWidth / 2 * canx),
                        cany * 0.85,
                        (classWidth * canx) - (ctx.lineWidth * 4));
                }

            }
            ctx.restore();

            if (!graysOnly) {
                ctx.save();
                ctx.fillStyle = "white";
                ctx.font = "400 1.5em Roboto Mono";
                ctx.textAlign = 'left';
                ctx.textBaseline = "middle";
                let brightTxtMeasure = ctx.measureText(`${(usePercents ? "% of all" : "# of")} CCs per brightness (excl. grays): `);
                ctx.fillText(`${(usePercents ? "% of all" : "# of")} CCs per brightness (excl. grays): `,
                    (canx * 0.08380), (cany * 0.973));

                ctx.shadowColor = "rgba(0,0,0,0.4)";
                ctx.shadowBlur = "5";
                ctx.shadowOffsetY = 30 / 8;
                let brightSquareWidth = canx * 0.8773 / 12;
                for (let i = 0; i < 5; i++) {
                    let brightCount = statData.chartInfo[selectedPart[0]].orderedColorFrequenciesPerValue[i];
                    if (parseInt(selectedPart[1]) === i) {
                        ctx.lineWidth = 4;
                        ctx.strokeStyle = "white";
                        roundRect(ctx,
                            (canx * 0.08380) + brightTxtMeasure.width + (6 * i) + (brightSquareWidth * i),
                            (cany * 0.973) - brightTxtMeasure.actualBoundingBoxAscent - 3,
                            brightSquareWidth,
                            30, {
                            tl: 5,
                            tr: 5,
                            br: 5,
                            bl: 5
                        }, false, true);
                        ctx.lineWidth = 3;
                    }
                    else {
                        ctx.lineWidth = 3;
                        ctx.strokeStyle = "rgba(0,0,0,0.3)";
                    }

                    ctx.fillStyle = hsvToHslCss(hsvBrightnesses[i][0], hsvBrightnesses[i][1], hsvBrightnesses[i][2]);
                    roundRect(ctx,
                        (canx * 0.08380) + brightTxtMeasure.width + (ctx.lineWidth * 2 * i) + (brightSquareWidth * i),
                        (cany * 0.973) - brightTxtMeasure.actualBoundingBoxAscent - ctx.lineWidth,
                        brightSquareWidth,
                        30, {
                        tl: 5,
                        tr: 5,
                        br: 5,
                        bl: 5
                    }, true, false);

                    ctx.fillStyle = "white";
                    ctx.textAlign = "center";
                    ctx.fillText(`${usePercents ? `${(brightCount / totalFrequency * 100).toFixed(2)}%` : brightCount}`,
                        (canx * 0.08380) + brightTxtMeasure.width + (ctx.lineWidth * 2 * i) + (brightSquareWidth * (i + 1)) - (brightSquareWidth / 2),
                        (cany * 0.973),
                        brightSquareWidth);
                }
                ctx.restore();
            }

            // Draw canvas lines ontop
            ctx.strokeStyle = "white";
            ctx.lineWidth = 4;
            ctx.beginPath();
            ctx.moveTo(canx * 0.08380, cany * 0.11415);
            ctx.lineTo(canx * 0.08380, cany * 0.78);
            ctx.lineTo(canx * 0.963068, cany * 0.78);
            ctx.stroke();
            ctx.closePath();

            // Clip the top part (for zoom)
            // Cheap technique, but it works.
            ctx.clearRect(canx * 0.08380, 0, canx * 0.879268, cany * 0.11415);

            ctx.save();
            // Extra information
            ctx.font = "100 4em Roboto Mono";
            ctx.textAlign = 'right';
            ctx.textBaseline = "bottom";
            ctx.fillStyle = "rgba(255, 255, 255, 0.6)";
            let zoomPctMeasure = ctx.measureText(`${graphZoom * 100}%`);
            ctx.fillText(`${graphZoom * 100}%`, canx * 0.963068, cany * 0.08893);
            ctx.font = "300 2em Roboto Mono";
            ctx.textBaseline = "bottom";
            ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
            ctx.fillText("GRAPH ZOOM", canx * 0.963068 - zoomPctMeasure.width, (cany * 0.08893) + zoomPctMeasure.actualBoundingBoxDescent);

            ctx.font = "400 2.25em Roboto Mono";
            ctx.fillStyle = "white";
            ctx.textBaseline = "bottom";
            ctx.textAlign = "left";
            ctx.fillText(friendlyNames[selectedPart[0]], canx * 0.08380, cany * 0.113, canx * 0.7)
            ctx.restore();


        }

        let canvasObj = canvasRef.current
        let ctx = canvasObj.getContext('2d')

        if (!initialized) {
            let _classes = [[], []];

            for (let i = 0; i < 360; i += 30) {
                _classes[0].push([i,
                    hsvToHslCss(i, 100, 100),
                    hsvToHslCss((i + 30), 100, 100),
                    [hsvToHslCss(i, 15, 100),
                    hsvToHslCss(i, 28.33, 100),
                    hsvToHslCss(i, 56.67, 100),
                    hsvToHslCss(i, 85, 100)]
                ]);
            }

            for (let i = 0; i < 100; i += 10) {
                _classes[1].push([`gray_i`,
                    hsvToHslCss(0, 0, i),
                    hsvToHslCss(0, 0, i + 10),
                    [hsvToHslCss(0, 0, i)]
                ]);
            }

            setClasses(_classes);
            setInitialized(true);
        }

        if (dataUpdateNecessary && !fetchErr[0] && statData) {
            setTotalFrequency(statData.totalCount);
            switch (dataSliceMode) {
                case 0:
                default:
                    setMaxValue(Math.max(...statData.chartInfo[selectedPart[0]].orderedTotalFrequencies));
                    break;
                case 1:
                    setMaxValue(Math.max(...statData.chartInfo[selectedPart[0]].orderedColorFrequencies));
                    break;
                case 2:
                    setMaxValue(Math.max(...statData.chartInfo[selectedPart[0]].grayFrequencies));
                    break;
            }
            setDataUpdateNecessary(false);
        }

        if (!ready) {
            setReady(true);
        }

        if (initialized) {
            switch (dataSliceMode) {
                case 0:
                default:
                    drawAxes(ctx, classes[0].concat(classes[1]));
                    break;
                case 1:
                    drawAxes(ctx, classes[0]);
                    break;
                case 2:
                    drawAxes(ctx, classes[1]);
                    break;
            }
        }
    }, [initialized, dataUpdateNecessary, selectedPart, graphZoom, fetchErr, graphDivSize, usePercents, ready])

    useEffect(() => {
        return () => {
            setInitialized(false);
            setReady(false);
        }
    }, []);

    useEffect(() => {
        if (statData)
            setDataUpdateNecessary(true);
    }, [statData, isSmall])

    const changeSPart = (part) => {
        let item = selectedPart;
        item[0] = part;
        if (friendlyBrightnessNames)
            setSelectedPart(item);
        setDataUpdateNecessary(true);
    }

    const changeSBright = (bright) => {
        let item = selectedPart;
        item[1] = bright;
        setSelectedPart(item);
        setDataUpdateNecessary(true);
    }

    return (
        <div className="grid-x">
            <div id="colorGraph" className={`grid-y small-12 large-${graphDivSizes[graphDivSize]} cell smooth-resize width`}>
                <div className="darker grid-x grid-margin-x">
                    <div className="cell small-6 grid-y">
                        <div>
                            <label for="part-select">Colors</label>
                        </div>
                        <div>
                            <select className="fullwidth" value={selectedPart[0]} name="part-select" onChange={(e) => changeSPart(e.target.value)}>
                                <optgroup label="Classic parts">
                                    {friendlyValues.classic.map(x => {
                                        return <Fragment key={x}><option value={x}>{friendlyNames[x]}</option></Fragment>
                                    })}
                                </optgroup>
                                <optgroup label="SPARK parts">
                                    {friendlyValues.spark.map(x => {
                                        return <Fragment key={x}><option value={x}>{friendlyNames[x]}</option></Fragment>
                                    })}
                                </optgroup>

                            </select>
                        </div>
                    </div>
                    <div className="cell small-6 grid-y">
                        <div>
                            <label for="brightness-select">Brightness</label>
                        </div>
                        <div>
                            <select name="brightness-select" className="value-gradient fullwidth" value={selectedPart[1]} onChange={(e) => changeSBright(e.target.value)}>
                                {friendlyBrightnessNames.map((x, i) => {
                                    return <Fragment key={i}><option value={i}>{x}</option></Fragment>
                                })}
                            </select>
                        </div>
                    </div>

                </div>
                <div className="darker">
                    <canvas className="" ref={canvasRef} width={1408} height={732} style={{ minWidth: "100%", maxWidth: "100%" }} />
                </div>
                <div className="darker grid-x grid-margin-x">
                    <div className="cell grid-y small-5">
                        <div className="cell">Zoom to bottom:</div>
                        <div className="cell"><input type="range" value={graphZoom} min="1" max="80" step="0.5" onChange={(e) => setGraphZoom(e.target.value)}></input></div>
                    </div>
                    <div className="cell grid-y small-7">
                        <div className="cell">Display values as:</div>
                        <div className="cell grid-x grid-padding-y align-center-middle button-array dark ">
                            <button className={`${(usePercents && "active")}`} onClick={() => { setReady(false); setUsePercents(true); }}>%</button>
                            <button className={`${(!usePercents && "active")}`} onClick={() => { setReady(false); setUsePercents(false); }}>#</button>
                        </div>

                    </div>

                </div>
            </div>
            <div className={`small-12 large-${graphDivSize === 2 ? "12" : "5"} grid-y grid-padding-y align-middle smooth-resize width`}>
                <h2>Chart control</h2>
                <div className="cell grid-x button-array dark align-center">
                    <button className={`${(dataSliceMode === 0 && "active")}`} onClick={() => { setDataSliceMode(0); scrollSmoothToId("#colorGraph"); setReady(false); setDataUpdateNecessary(true); }}>Full</button>
                    <button className={`${(dataSliceMode === 1 && "active")}`} onClick={() => { setDataSliceMode(1); scrollSmoothToId("#colorGraph"); setReady(false); setDataUpdateNecessary(true); }}>Colors</button>
                    <button className={`${(dataSliceMode === 2 && "active")}`} onClick={() => { setDataSliceMode(2); scrollSmoothToId("#colorGraph"); setReady(false); setDataUpdateNecessary(true); }}>Grays</button>
                </div>
                <div className="cell grid-x button-array dark align-center" style={{ display: (isSmall ? "none" : "inherit") }}>
                    <button className={`${(graphDivSize === 0 && "active")}`} onClick={() => { scrollSmoothToId("#colorGraph"); setGraphDivSize(0); }}>Small Chart</button>
                    <button className={`${(graphDivSize === 1 && "active")}`} onClick={() => { scrollSmoothToId("#colorGraph"); setGraphDivSize(1); }}>Normal Chart</button>
                    <button className={`${(graphDivSize === 2 && "active")}`} onClick={() => { scrollSmoothToId("#colorGraph"); setGraphDivSize(2); }}>Big Chart</button>
                </div>
                {statData && <GraphLastUpdatedText statData={statData} />}
                <MaxCCsPanel />
            </div>
        </div>

    )
}

export default StatisticsPage;