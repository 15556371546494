import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import StatusIcon from "./components/cmtsStatusIcon.js";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faEllipsisH, faShare, faTimes, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Modal from 'react-modal';
import { ReactComponent as LookupCC } from "./icons/lookup_cc.svg";
const _ = require("lodash");
const fetch = require("node-fetch");

Modal.setAppElement(document.querySelector('#page-bounds'));

function ColorCodeList() {
    const [ccList, setCCList] = useState([]);
    const [doneFetching, setDoneFetching] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [doneCopying, setDoneCopying] = useState(true);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    // Structure: String, error number, ETA (Date string)
    const [errorInfo, setErrorInfo] = useState(["No error", 0, ""]);
    const [userInfo, setUserInfo] = useState(null);
    let { user, ccid = "default" } = useParams();

    useEffect(() => {

        const getColorCodeList = async () => {

            try {
                let jsonres = await (await fetch(`http${(process.env.REACT_APP_HTTP_APPEND && "s")}://${process.env.REACT_APP_API_URL}/users/${userInfo.user.id}/colorcodes`,
                    {
                        method: "GET"
                    })).json();
                if (jsonres.success === true) {
                    setCCList(jsonres.colorcodes);
                }
                else {
                    // TODO: Make a better errorcode handler, using the actual CMTS codes
                    let errcode;
                    switch (jsonres.code) {
                        case 301:
                            errcode = 2;
                            break;
                        case 398:
                            // Error 3: Maintenance
                            errcode = 3;
                            break;
                        case 399:
                        default:
                            // Error 4: Emergency
                            errcode = 4;
                            break;
                    }
                    setErrorInfo([jsonres.message, errcode, jsonres.eta]);
                }
            }
            catch (ex) {
                // Error 1, disconnected
                setErrorInfo(["", 1, 0]);
            }
            setDoneFetching(true);
        }

        const getUserObject = async () => {
            let prepend = "";
            if (isNaN(user)) {
                prepend += "handle/";
            }
            let jsonres = await (await fetch(`http${(process.env.REACT_APP_HTTP_APPEND && "s")}://${process.env.REACT_APP_API_URL}/users/${prepend + user}`,
                {
                    method: "GET"
                })).json();
            if (jsonres.success === true) {

                setUserInfo(jsonres);
            }
        }

        // --- Actual useEffect starts here ---
        if (userInfo === null) {
            getUserObject();
            return;
        }

        if (!doneFetching && userInfo !== null) {
            getColorCodeList();
        }
    }, [doneFetching, ccList, user, ccid, imageLoaded, userInfo, errorInfo]);

    const ccExists = () => {
        let cc = _.find(ccList, (x) => x.id === ccid);
        return (!(_.isUndefined(cc) || _.isNull(cc)));
    }

    const copyColorCode = (id, onCopied) => {
        async function fetchCC() {
            let jsonres = await (await fetch(`http${(process.env.REACT_APP_HTTP_APPEND && "s")}://${process.env.REACT_APP_API_URL}/users/${userInfo.user.id}/colorcodes/${id}?type=1`,
                {
                    method: "GET"
                })).json();
            if (jsonres.code === 200) {
                navigator.clipboard.writeText(jsonres.colorcode);
                onCopied();
                setDoneCopying(true)
                return true;
            }
            else {
                setDoneCopying(true)
                throw Error([jsonres.code, jsonres.message]);
            }
        }

        try {
            if (doneCopying) {
                setDoneCopying(false);
                fetchCC();
            }

        } catch (ex) {
            alert(`Error: ${ex[1]}\nError code: ${ex[0]}`);
        }
    }

    const getCCInfo = () => {
        let cc = (_.find(ccList, (x) => x.id === ccid));
        if (cc !== undefined) {
            return cc.info
        }
        else {
            return null;
        }
    }

    const openModal = () => {
        setShareModalOpen(true);
    }

    const closeModal = () => {
        setShareModalOpen(false);
    }

    const renderCCWidget = () => {
        if (doneFetching) {
            if (errorInfo[1] > 0) {
                switch (errorInfo[1]) {
                    case 2:
                        return (
                            <>
                                <StatusIcon type="not-ready" info={{ reason: errorInfo[0] }} />
                            </>)
                    case 3:
                        return (
                            <>
                                <StatusIcon
                                    type="maintenance"
                                    info={{ reason: errorInfo[0], eta: new Date(errorInfo[2]*1000).toDateString() }} />
                            </>)
                    case 4:
                        return (
                            <>
                                <StatusIcon
                                    type="maintenance-unplanned"
                                    info={{ reason: errorInfo[0], date: new Date(errorInfo[2]*1000).toDateString() }} />
                            </>)
                    case 1:
                    default:
                        return (
                            <>
                                <StatusIcon type="disconnect" />
                            </>)
                }
            }
            // Return full CC list if everything is OK
            if (ccList.length > 0) {
                if (!ccExists()){
                    return (
                        <>
                            <div className="cell grid-y align-middle">
                                <StatusIcon type="404-cc" info={{ slot: ccid }} />
                            </div>
                        </>
                    )
                }

                return (
                    <>
                        <div className="cell">
                            <div className="grid-x align-middle cc-name">
                                <div className={`icon ${getCCInfo().type}`}></div>
                                <h3>{getCCInfo().name}</h3>
                            </div>

                        </div>
                        <div className="cell grid-x grid-margin-x" style={{ padding: ".5em 0em" }}>
                            <CopyButton
                                copying={doneCopying}
                                canGet={(_.find(ccList, (x) => x.id === ccid)).info.canGet || false}
                                onClick={(x) => {
                                    copyColorCode(ccid, x);
                                }
                                }
                            />
                            <button className={`button`} onClick={() => openModal()} aria-label="Share button">
                                <FontAwesomeIcon icon={faShare} />
                                <span style={{ flex: "1" }}>Share</span>
                            </button>
                        </div>
                        <div className="cell grid-y align-middle">
                            <ColorCodePreview
                                id={ccid}
                                usr={userInfo}
                                exists={ccExists()}
                                imageLoaded={imageLoaded}
                                onImageLoad={() => setImageLoaded(true)} />
                        </div>
                    </>)
            }
            // Return empty list message if there's no CCs
            else { return (<StatusIcon type="404-cclist" />) }
        }
        // Still loading
        else { return (<StatusIcon type="loading" />) }
    }

    const renderBottomHalf = () => {
        if (!doneFetching) return (
            <>
                <div className="cell grid-y small-12 large-cell-block-y align-middle">
                    <StatusIcon type="loading" />
                </div>
            </>
        )
        return (
            <>
                <div className="cell large-5 large-cell-block-y">
                    {
                        <ColorCodeNameList
                            list={ccList}
                            usr={user}
                            currentId={ccid}
                            onClick={(x) => {
                                if (x !== ccid)
                                    setImageLoaded(false);
                            }} />
                    }
                </div>
                <div className="cell large-7 large-cell-block-y">
                    <div className="cell grid-y grid-margin-x align-middle">
                        {renderCCWidget()}
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="panel grid-container grid-y full small-12 medium-11">
            <div className="title header">
                {
                    (doneFetching ?
                        <h1>CC List for {userInfo.user.handle || "this CMTS user"}</h1>
                        :
                        <h1>Getting data...</h1>
                    )
                }

            </div>
            <div className="bottom large-auto large-cell-block-container" style={{ height: "100%" }}>
                <div className="grid-x grid-margin-x grid-margin-y">
                    {renderBottomHalf()}
                </div>
            </div>
            <ShareModal
                shareModalOpen={shareModalOpen}
                closeModal={closeModal}
                id={ccid}
                user={user}
                name={(ccExists() ? getCCInfo.name : "")} />
        </div>
    )

}

function ShareModal({ shareModalOpen, closeModal, id, user, name }) {
    const [playingCopyAnimation, setPlayingCopyAnimation] = useState(false);

    const copy = () => {
        navigator.clipboard.writeText(`https://cmts64.xyz/${user}/cc/${id}`)
        setPlayingCopyAnimation(true);
    }

    useEffect(() => {
        if (playingCopyAnimation) {
            setTimeout(() => { setPlayingCopyAnimation(false) }, 1000);
        }
    }, [playingCopyAnimation, id, user])

    const shareTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?text=Check out this color code: "${name}"&url=https://cmts64.xyz/${user}/cc/${id}&hashtags=cometspectrum`, "TwitterShare", "menubar=off");
    }

    const shareFacebook = () => {
        window.open(`https://www.facebook.com/dialog/share?app_id=741726046777017&quote=Check out this color code: "${name}"&display=popup&href=https://cmts64.xyz/${user}/cc/${id}`, "FBShare", "menubar=off");
    }

    return (
        <Modal
            className="cell share-modal"
            overlayClassName="grid-x share-modal-overlay align-center align-middle"
            parentSelector={() => document.querySelector('#page-bounds')}
            isOpen={shareModalOpen}
            onRequestClose={closeModal}
            closeTimeoutMS={520}
            contentLabel="Share modal"
            ariaHideApp={false}
            style={{width: "100%"}}
        >
            <div className="grid-x modal-content align-center">
                <div className="cell grid-x grid-margin-x grid-padding-y medium-9 small-11">
                    <div className="cell grid-y grid-margin-y">
                        <div className="cell grid-x small-1 align-center-middle">
                            <div className="cell small-12 grid-x align-right">
                                <button className="empty-button" onClick={() => closeModal()} ><FontAwesomeIcon role="button" style={{ color: "#FFF", fontSize: "1.6em", cursor: "pointer" }} icon={faTimes} /></button>
                            </div>
                        </div>
                        <div className="grid-x align-right">
                            <div className="cell auto title"><h1>Share CC link</h1></div>
                        </div>
                        <div className="grid-x align-center-middle">
                            <div className="cell small-11 large-8">
                                <div className="share-textbox">
                                    <input readOnly="true" value={`https://cmts64.xyz/${user}/cc/${id}`} />
                                </div>
                            </div>
                        </div>
                        <div className="cell auto grid-x align-center-middle">
                            <div className="cell small-9 large-4 grid-x grid-margin-x align-spaced">
                                <button className={`social-button ${playingCopyAnimation ? "success" : ""}`} onClick={() => copy()}>
                                    {playingCopyAnimation ? <FontAwesomeIcon icon={faThumbsUp} /> : <FontAwesomeIcon icon={faCopy} />}</button>
                                <button className="social-button fb" onClick={() => shareFacebook()}><FontAwesomeIcon icon={faFacebook} /></button>
                                <button className="social-button twt" onClick={() => shareTwitter()}><FontAwesomeIcon icon={faTwitter} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>)
}

function CopyButton(props) {
    const [animating, setAnimating] = useState(false);

    const animate = () => {
        setAnimating(true);
    }

    useEffect(() => {
        if (animating) {
            setTimeout(() => setAnimating(false), 2000);
        }
    }, [animating, props.copying]);
    if (props.canGet) {
        return (
            <>
                <button className={`button ${(animating ? "success" : null)}`} onClick={() => props.onClick(animate)} aria-label="Copy button">
                    {(!props.copying ?
                        <>
                            <FontAwesomeIcon icon={faEllipsisH} />
                            <span style={{ flex: "1" }}>Please wait...</span>
                        </>
                        :
                        <>
                            <FontAwesomeIcon icon={faCopy} />
                            <span style={{ flex: "1" }}>{(animating ? "Copied!" : "Copy")}</span>
                        </>
                    )}

                </button>
            </>
        )
    }
    else {
        return (
            <>
                <button className={`button failure`} aria-label="Copy button">
                    <FontAwesomeIcon icon={faTimes} />
                    <span style={{ flex: "1" }}>Copy Disabled</span>
                </button>
            </>
        )
    }

}

function ColorCodeNameList(props) {
    const changeImageLoadStatus = (x) => {
        if (x !== props.currentId) {
            props.onClick();
        }
    }
    return (
        <>
            <div className="searchBar medium-text" style={{ marginBottom: "0.25em", width: "100%" }}>
                <input readOnly placeholder="Namesearch coming soon!" />
                <button className={"searchButton"} onClick={() => { }}><LookupCC className="image" style={{width: "100%"}} /></button>
            </div>
            <div className="grid-y cclist align-middle">
                <div className="cell grid-y">
                    {props.list.map(x => (
                        <Fragment key={x.id}>
                            <Link to={`/${props.usr}/cc/${(x.id !== "default" ? x.id : "")}`}
                                onClick={() => changeImageLoadStatus(x.id)}
                                replace
                            >
                                <div id={(x.id === props.currentId) ? "active" : null} className={`cell grid-x grid-padding-y list-element ${(x.id === props.currentId ? "active" : "")} searchResult`}>
                                    <div className={`icon ${x.info.type}`}></div>
                                    <div className="auto" style={{ minWidth: "0" }}>{x.info.name}</div>
                                </div>
                            </Link>
                        </Fragment>
                    ))}
                </div>
            </div>

        </>
    )
}

function ColorCodePreview(props) {
    if (props.exists) {
        return (
            <>
                <img alt="Color code preview" style={{ display: (props.imageLoaded ? "inherit" : "none") }} onLoad={props.onImageLoad} src={`http${(process.env.REACT_APP_HTTP_APPEND && "s")}://${process.env.REACT_APP_API_URL}/users/${props.usr.user.id}/colorcodes/${props.id}/preview.png`}></img>
                <StatusIcon type="loading" style={{ display: (props.imageLoaded ? "none" : "inherit") }} />
            </>
        )
    }
    else {
        return (
            <>
                <StatusIcon type="404-cc" />
            </>
        )
    }

}

export default ColorCodeList;