/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import './App.scss';
import './css/cmts.scss';
import './css/main.scss';
import './css/animations.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainSearch from "./cmtsSearch.js";
import CMTSResultsPage from "./cmtsResultsPage.js";
import ColorCodeList from "./cmtsColorCodeView.js";
import CMTSStatistics from "./cmtsStatistics.js";
import CMTSAbout from "./cmtsAbout.js";
import { ListRouterLink } from "./components/cmtsNavigation.js";
import { ReactComponent as CMTSRailroad404 } from "./media/notfoundrail.svg";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useParams,
} from "react-router-dom";
import { random } from 'lodash';
import { useMediaQuery } from 'react-responsive'
import { faBars, faChevronLeft, faChevronUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Sparklize from './cmtsSparklize';

function App(props) {
    const [sidebarHidden, setSidebarHidden] = useState(true);
    const [responsiveHidden, setResponsiveHidden] = useState(true);
    const isSmall = useMediaQuery({ query: "(max-width: 39.9375em)" });
    const [wallpaperIndex, setWallpaperIndex] = useState(-1);

    const getWallpaperFromIndex = (index, ready) => {
        if (index === -1) {
            return ("not-ready");
        }
        let wp = ["anniversary", "anniversary", "anniversary", "anniversary", "anniversary", "aurora", "desert", "synth-l", "synth-d", "waterfall", "island", "ttc"];
        return (wp[index % wp.length]);
    }

    useEffect((upd) => {
        setWallpaperIndex(random(0, 6, false));
    }, [])

    return (
        <Router>
            <>{ /* Sidebar start */}</>
            <div className="input-eater" style={{ display: (sidebarHidden ? "none" : "initial") }} onClick={
                () => setSidebarHidden(!sidebarHidden)
            } />
            <SideBar hidden={sidebarHidden} tabIndex={`${sidebarHidden ? "-1" : "0"}`}>
                <li className="active">
                    <span className="icon"
                        style={{ cursor: "pointer" }}
                        role="button" tabIndex="1"
                        onClick={() => setSidebarHidden(!sidebarHidden)}><FontAwesomeIcon icon={faChevronLeft} />
                    </span><span>comet<strong>spectrum</strong></span>
                </li>
                <a href="https://comet.glitchypsi.xyz">
                    <li>
                        <span className="icon comet-logo"></span><span>Project Comet</span>
                    </li>
                </a>
                <a href="https://glitchypsi.xyz">
                    <li>
                        <span className="icon glitchypsi-logo"></span><span>GlitchyPSI</span>
                    </li>
                </a>
            </SideBar>
            { /* Sidebar end */}
            { /* Page container (Main Page) start */}
            <div className={`container ${(!sidebarHidden && 'content-background')}`}>
                { /* Top bar (Navbar + Acctbar) start */}
                <div className="topbar top-bar">
                    <ul>
                        <button tabIndex="1" id="menuDrawer" className="decorativeButton" onClick={
                            () => setSidebarHidden(!sidebarHidden)
                        }>
                            <li className="activeIcon">
                                <span className="icon spectrum-logo menuButton"></span>
                            </li>
                        </button>
                    </ul>
                    { /* Topbar wrapper for when Screen is Small:
                     if small, replace with Drawer menu */ }
                    {isSmall ? <button className="blank-button" onClick={() => setResponsiveHidden(false)}>
                        <FontAwesomeIcon icon={faBars} />
                    </button> :
                        <NavBar hidden={!sidebarHidden}
                            leftside={<NavigationLinks />}
                            rightside={<AccountPartial />}
                        />
                    }
                </div>
                { /* Top bar (Navbar + Acctbar) end */}
                { /* Mobile slidebar (navbar equiv) start */}
                {(isSmall &&
                    <NavBar mobile={true} hidden={responsiveHidden}
                        leftside={<>
                            <div className="back-button">
                                <button style={{ justifyContent: "center" }} onClick={() => setResponsiveHidden(true)}>
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </button>
                            </div>
                            <NavigationLinks onClick={() => setResponsiveHidden(true)} />
                        </>}
                        rightside={<AccountPartial />}
                    />)}
                { /* Mobile slidebar (navbar equiv) end */}
                { /* Page Bounds (Page content) start */}
                <div className="page-bounds">
                    <div id="page-bounds" className={`content grid-container fluid`}>
                        <div className={`grid-y grid-margin-x grid-margin-y align-center-middle`} style={{ paddingTop: "1em" }}>
                            <Switch>
                                <Route exact path="/">
                                    <MainSearch />
                                </Route>
                                <Route path="/about">
                                    <CMTSAbout />
                                </Route>
                                <Route path="/editor">
                                    <NotFoundMaybe />
                                </Route>
                                <Route path="/search">
                                    <CMTSResultsPage />
                                </Route>
                                <Route path="/stats">
                                    <CMTSStatistics />
                                </Route>
                                <Route path="/sparklize">
                                    <Sparklize />
                                </Route>
                                <Route path="/ccs/:user/:ccid?">
                                    <CCListRedirect />
                                </Route>
                                <Route path="/:user/cc/:ccid?">
                                    <ColorCodeList />
                                </Route>
                                <Route>
                                    <NotFoundMaybe />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
                { /* Page Bounds (Page content) end */}
            </div>
            { /* Page container (Main Page) end */}
            { /* ⬇ Full size BG */}
            <div className={`cares-me-not full-bg ${getWallpaperFromIndex(wallpaperIndex)} ${!sidebarHidden ? 'content-background' : ''}`}>
            </div>
        </Router>
    );
}

function CCListRedirect() {
    let { user, ccid = "default" } = useParams();
    return <Redirect to={`/${user}/cc/${ccid}`} />
}

function NavigationLinks({ onClick }) {
    return (
        <>
            <ListRouterLink onClick={onClick} to="/" activeOnlyWhenExact="true" label="LOOKUP" />
            <ListRouterLink onClick={onClick} to="/about" label="ABOUT" />
            <ListRouterLink onClick={onClick} to="/sparklize" label="SPARKLIZER" />
            <ListRouterLink onClick={onClick} to="/stats" label="STATS" />
            <a href="https://discord.com/api/oauth2/authorize?client_id=724279495938408529&permissions=124928&scope=bot">
                <li>
                    INVITE BOT
                </li>
            </a>

            <a href="https://discord.gg/DEyeFCPRAu">
                <li>
                    DISCORD SERVER
                </li>

            </a>
        </>
    )
}

function NotFoundMaybe() {
    return (
        <>
            <CMTSRailroad404 className="small-4" style={{ height: "30%" }} />
            <div className="status-text"><p>Nothing found!<br />Although, that may be a mistake.<br />If you think it is a mistake, notify GlitchyPSI.</p></div>
        </>
    )
}

function AccountPartial(props) {
    return (
        <>
            <li>
                <div className="accountTopBar" title="This does nothing yet!" style={{ cursor: "not-allowed" }}>
                    <div className="pfp" style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/media/cmts_unknown.png")` }}></div>
                    <FontAwesomeIcon icon={faCaretDown} />
                </div>
            </li>
        </>
    )
}

function SideBar(props) {
    return (
        <div className={`sidebar ${props.hidden ? 'sidebar-hide' : ''}`}>
            <ul>
                {props.children}
            </ul>
        </div>
    )
}

function NavBar(props) {
    const [hidden, setHidden] = useState(true);
    useEffect(() => {
        if (props.hidden && props.mobile) {
            setTimeout(() => setHidden(true), 500);
        }
        else if (!props.hidden) {
            setHidden(false);
        }
    }, [props.hidden, props.mobile]);

    const getVisibilityClass = () => {
        if (!props.mobile && props.hidden) {
            return "hidden";
        }
        if (props.mobile) {
            if (props.hidden && hidden) {
                return "mobile hidden";
            }
            else if (props.hidden && !hidden) {
                return "mobile invisible";
            }
            return "mobile"
        }
        return "";
    }

    return (
        <div className={`navbar navbar-container ${getVisibilityClass()}`}>
            <div className="top-bar-left">
                <ul>
                    {props.leftside}
                </ul>
            </div>
            <div className="top-bar-right">
                <ul>
                    {props.rightside}
                </ul>
            </div>
        </div>
    )
}

export default App;
